// src/pages/NotFoundPage/NotFoundPage.tsx
import React from 'react';
import { Link } from 'react-router-dom';
// import './NotFoundPage.css'; // Create appropriate styles

const NotFoundPage: React.FC = () => (
    <div className="not-found">
        <h1>404 - Page Not Found</h1>
        <p>The page you're looking for doesn't exist.</p>
        <Link to="/login">Go to Login</Link>
    </div>
);

export default NotFoundPage;
