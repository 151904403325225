// src/styles/scrollbarStyles.ts
import { css, Theme } from '@emotion/react';

export const scrollbarStyles = (theme: Theme) => css`
  scrollbar-width: thin;
  scrollbar-color: ${theme.fadedText} ${theme.background};

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${theme.background};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.primaryDark};
    border-radius: 10px;
    border: 2px solid ${theme.background};
  }
`;
