// src/components/Modal/Modal.styles.ts

import { css, Theme } from '@emotion/react';


export const modalOverlay = (theme: Theme) => css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const modalContainer = (theme: Theme) => css`
  background: ${theme.surface};
  color: ${theme.onSurface};
  padding: 24px;
  border-radius: ${theme.borderRadius};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  width: 500px;
  max-width: 90%;
  position: relative;
  z-index: 1000;
`;

export const modalHeader = (theme: Theme) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const modalTitle = (theme: Theme) => css`
  font-size: ${theme.typography.fontSize.large};
  font-weight: ${theme.typography.fontWeight.bold};
`;

export const modalSubtitle = (theme: Theme) => css`
  font-size: ${theme.typography.fontSize.medium};
  color: ${theme.onSurfaceDimmed};
`;

export const closeButtonStyle = (theme: Theme) => css`
  cursor: pointer;
  background: transparent;
  border: none;
  color: ${theme.onSurface};
  font-size: 18px;
  &:hover {
    color: ${theme.primary};
  }
`;

export const modalBody = (theme: Theme) => css`
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 16px;
`;

export const modalFooter = (theme: Theme) => css`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 16px;
`;
