// src/components/IconButton/IconButton.tsx
/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, Theme } from '@emotion/react';
import { useTheme } from '../../context/ThemeContext';

interface IconButtonProps {
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    ariaLabel: string;
    children: React.ReactNode;
    style?: React.CSSProperties;
    size?: 'tiny' | 'small' | 'medium' | 'large';
    disabled?: boolean;
    iconColor?: string;
    hoverColor?: string;
}

const IconButton: React.FC<IconButtonProps> = ({
    onClick,
    ariaLabel,
    children,
    style,
    size = 'medium',
    disabled = false,
    iconColor,
    hoverColor,
}) => {
    const { theme } = useTheme();

    return (
        <button
            onClick={!disabled ? onClick : undefined}
            aria-label={ariaLabel}
            css={buttonStyle(theme, size, disabled, iconColor, hoverColor)}
            style={style}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default IconButton;

const buttonStyle = (
    theme: Theme,
    size: 'tiny' | 'small' | 'medium' | 'large',
    disabled: boolean,
    iconColor?: string,
    hoverColor?: string
) => {
    const sizes = {
        tiny: {
            padding: '4px',
            iconSize: '16px',
        },
        small: {
            padding: '6px',
            iconSize: '18px',
        },
        medium: {
            padding: '8px',
            iconSize: '20px',
        },
        large: {
            padding: '12px',
            iconSize: '32px',
        },
    };

    return css`
        background: none;
        border: none;
        padding: ${sizes[size].padding};
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${disabled
            ? theme.fadedText
            : iconColor
                ? iconColor
                : '#757b8f'}; /* Use iconColor prop if provided */

        &:hover {
            color: ${!disabled
            ? hoverColor
                ? hoverColor
                : theme.primary
            : undefined}; /* Use hoverColor prop if provided */
        }

        &:focus {
            outline: none;
        }

        svg {
            display: block;
            width: ${sizes[size].iconSize};
            height: ${sizes[size].iconSize};
        }

        &:disabled {
            opacity: 0.6;
        }
    `;
};
