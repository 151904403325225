// src/components/Badge/Badge.tsx
/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { useTheme } from '../../context/ThemeContext';

interface BadgeProps {
    count: number;
    children: React.ReactNode;
}

const Badge: React.FC<BadgeProps> = ({ count, children }) => {
    const { theme } = useTheme();
    return (
        <div
            css={css`
        position: relative;
        display: inline-block;
      `}
        >
            {children}
            {count > 0 && (
                <span
                    css={css`
            position: absolute;
            top: -10px;
            right: -7px;
            background-color: ${theme.error};
            color: ${theme.onPrimary};
            border-radius: 50%;
            padding: 4px 6px;
            font-size: 11px;
            line-height: 1;
          `}
                >
                    {count}
                </span>
            )}
        </div>
    );
};

export default React.memo(Badge);
