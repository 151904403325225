// src/components/Spinner/Spinner.tsx
/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, keyframes } from '@emotion/react';
import { useTheme } from '../../context/ThemeContext';

// Animation for the candlestick
const candlestickAnimation = keyframes`
  0%, 100% {
    transform: scaleY(0.8);
    opacity: 0.8;
  }
  50% {
    transform: scaleY(1.2);
    opacity: 1;
  }
`;

// Animation for the spinning circle
const circleAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Base styles for the spinner container
const spinnerStyle = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

// Styles for the candlestick including wicks
const candlestickStyle = (color: string, delay: string) => css`
  position: relative;
  width: 4px;
  height: 16px;
  background-color: ${color};
  margin: 0 2px;
  animation: ${candlestickAnimation} 1s infinite ease-in-out;
  animation-delay: ${delay};

  &:before, &:after {
    content: '';
    position: absolute;
    width: 2px;
    background-color: ${color};
    left: 50%;
    transform: translateX(-50%);
  }

  &:before {
    top: -4px;
    height: 4px;
  }

  &:after {
    bottom: -4px;
    height: 4px;
  }

  @media (max-width: 600px) {
    width: 3px;
    height: 12px;
    margin: 0 1px;
  }
`;

// Styles for the spinning circle
const circleStyle = (primaryColor: string) => css`
  position: absolute;
  width: 35px;
  height: 35px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  border-top-color: ${primaryColor}; /* Use theme's primary color for the top border */
  animation: ${circleAnimation} 1.5s linear infinite;
`;

const Spinner: React.FC = () => {
  const { theme } = useTheme(); // Access the theme

  return (
    <div css={spinnerStyle}>
      <div css={circleStyle(theme.primary)} />
      <div css={candlestickStyle('#4caf50', '0s')} /> {/* Green candlestick */}
      <div css={candlestickStyle('#f44336', '-0.5s')} /> {/* Red candlestick */}
      <div css={candlestickStyle('#4caf50', '-0.25s')} /> {/* Green candlestick */}
    </div>
  );
};

export default Spinner;

// // ---- without the circle 
// /** @jsxImportSource @emotion/react */
// import React from 'react';
// import { css, keyframes } from '@emotion/react';

// // Animation for the candlestick
// const candlestickAnimation = keyframes`
//   0%, 100% {
//     transform: scaleY(0.8);
//     opacity: 0.8;
//   }
//   50% {
//     transform: scaleY(1.2);
//     opacity: 1;
//   }
// `;

// // Base styles for the spinner container
// const spinnerStyle = css`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
// `;

// // Styles for the candlestick including wicks
// const candlestickStyle = (color: string, delay: string) => css`
//   position: relative;
//   width: 4px; /* Reduced width for compactness */
//   height: 16px; /* Reduced height for compactness */
//   background-color: ${color};
//   margin: 0 2px; /* Reduced margin for compactness */
//   animation: ${candlestickAnimation} 1s infinite ease-in-out;
//   animation-delay: ${delay};

//   &:before, &:after {
//     content: '';
//     position: absolute;
//     width: 2px;
//     background-color: ${color};
//     left: 50%;
//     transform: translateX(-50%);
//   }

//   &:before {
//     top: -4px; /* Reduced upper wick length */
//     height: 4px; /* Reduced upper wick height */
//   }

//   &:after {
//     bottom: -4px; /* Reduced lower wick length */
//     height: 4px; /* Reduced lower wick height */
//   }

//   @media (max-width: 600px) {
//     width: 3px; /* Further reduce width on smaller screens */
//     height: 12px; /* Further reduce height on smaller screens */
//     margin: 0 1px; /* Reduce margin on smaller screens */
//   }
// `;

// const Spinner: React.FC = () => {
//   return (
//     <div css={spinnerStyle}>
//       <div css={candlestickStyle('#4caf50', '0s')} /> {/* Green candlestick */}
//       <div css={candlestickStyle('#f44336', '-0.5s')} /> {/* Red candlestick */}
//       <div css={candlestickStyle('#4caf50', '-0.25s')} /> {/* Green candlestick */}
//     </div>
//   );
// };

// export default Spinner;


// /** @jsxImportSource @emotion/react */
// import React from 'react';
// import { css, keyframes } from '@emotion/react';

// // Animation for the candlestick
// const candlestickAnimation = keyframes`
//   0%, 100% {
//     transform: scaleY(0.8);
//     opacity: 0.8;
//   }
//   50% {
//     transform: scaleY(1.2);
//     opacity: 1;
//   }
// `;

// // Base styles for the spinner container
// const spinnerStyle = css`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
// `;

// // Styles for the candlestick including wicks
// const candlestickStyle = (color: string, delay: string) => css`
//   position: relative;
//   width: 4px; /* Reduced width for compactness */
//   height: 16px; /* Reduced height for compactness */
//   background-color: ${color};
//   margin: 0 2px; /* Reduced margin for compactness */
//   animation: ${candlestickAnimation} 1s infinite ease-in-out;
//   animation-delay: ${delay};

//   &:before, &:after {
//     content: '';
//     position: absolute;
//     width: 2px;
//     background-color: ${color};
//     left: 50%;
//     transform: translateX(-50%);
//   }

//   &:before {
//     top: -4px; /* Reduced upper wick length */
//     height: 4px; /* Reduced upper wick height */
//   }

//   &:after {
//     bottom: -4px; /* Reduced lower wick length */
//     height: 4px; /* Reduced lower wick height */
//   }

//   @media (max-width: 600px) {
//     width: 3px; /* Further reduce width on smaller screens */
//     height: 12px; /* Further reduce height on smaller screens */
//     margin: 0 1px; /* Reduce margin on smaller screens */
//   }
// `;

// const Spinner: React.FC = () => {
//   return (
//     <div css={spinnerStyle}>
//       <div css={candlestickStyle('#4caf50', '0s')} /> {/* Green candlestick */}
//       <div css={candlestickStyle('#f44336', '-0.5s')} /> {/* Red candlestick */}
//       <div css={candlestickStyle('#4caf50', '-0.25s')} /> {/* Green candlestick */}
//     </div>
//   );
// };

// export default Spinner;


// /** @jsxImportSource @emotion/react */
// import React from 'react';
// import { css, keyframes } from '@emotion/react';

// const candlestickGrow = keyframes`
//   0%, 100% {
//     transform: scaleY(1);
//   }
//   50% {
//     transform: scaleY(2);
//   }
// `;

// const spinnerStyle = css`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
// `;

// const candlestickWrapperStyle = css`
//   display: flex;
//   align-items: flex-end;
//   height: 50px;
//   margin: 0 2px;
// `;

// const candleBodyStyle = (color: string, delay: string) => css`
//   width: 6px;
//   height: 20px;
//   background-color: ${color};
//   animation: ${candlestickGrow} 1s ${delay} infinite ease-in-out;
//   transform-origin: bottom;
// `;

// const candleWickStyle = (color: string) => css`
//   width: 2px;
//   height: 10px;
//   background-color: ${color};
//   margin: 0 auto;
// `;

// const Spinner: React.FC = () => {
//   return (
//     <div css={spinnerStyle}>
//       <div css={candlestickWrapperStyle}>
//         <div css={candleWickStyle('#4caf50')} />
//         <div css={candleBodyStyle('#4caf50', '0s')} />
//       </div>
//       <div css={candlestickWrapperStyle}>
//         <div css={candleWickStyle('#f44336')} />
//         <div css={candleBodyStyle('#f44336', '0.2s')} />
//       </div>
//       <div css={candlestickWrapperStyle}>
//         <div css={candleWickStyle('#4caf50')} />
//         <div css={candleBodyStyle('#4caf50', '0.4s')} />
//       </div>
//     </div>
//   );
// };

// export default Spinner;


// ------ candlestick spinner 2 ----
// /** @jsxImportSource @emotion/react */
// import React from 'react';
// import { css, keyframes } from '@emotion/react';

// // Animation for the candlestick going up and down
// const candlestickMovement = keyframes`
//   0%, 100% {
//     transform: translateY(0) scaleY(1);
//   }
//   50% {
//     transform: translateY(-10px) scaleY(1.5);
//   }
// `;

// // Base styles for the spinner container
// const spinnerStyle = css`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
// `;

// // Styles for the candlestick with up and down movement
// const candlestickStyle = (color: string, delay: string) => css`
//   position: relative;
//   width: 6px;
//   height: 20px;
//   background-color: ${color};
//   margin: 0 4px;
//   animation: ${candlestickMovement} 1.2s infinite ease-in-out;
//   animation-delay: ${delay};

//   &:before, &:after {
//     content: '';
//     position: absolute;
//     width: 2px;
//     background-color: ${color};
//     left: 50%;
//     transform: translateX(-50%);
//   }

//   &:before {
//     top: -8px; /* Length of upper wick */
//     height: 8px; /* Upper wick height */
//   }

//   &:after {
//     bottom: -8px; /* Length of lower wick */
//     height: 8px; /* Lower wick height */
//   }
// `;

// const Spinner: React.FC = () => {
//   return (
//     <div css={spinnerStyle}>
//       <div css={candlestickStyle('#4caf50', '0s')} />  {/* Green candlestick moving up */}
//       <div css={candlestickStyle('#f44336', '-0.4s')} /> {/* Red candlestick moving down */}
//       <div css={candlestickStyle('#4caf50', '-0.2s')} />  {/* Green candlestick moving up */}
//     </div>
//   );
// };

// export default Spinner;

// ------ candlestick spinner 1 ----
// /** @jsxImportSource @emotion/react */
// import React from 'react';
// import { css, keyframes } from '@emotion/react';

// // Animation for the candlestick
// const candlestickAnimation = keyframes`
//   0%, 100% {
//     transform: scaleY(0.8);
//     opacity: 0.8;
//   }
//   50% {
//     transform: scaleY(1.2);
//     opacity: 1;
//   }
// `;

// // Base styles for the spinner container
// const spinnerStyle = css`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
// `;

// // Styles for the candlestick including wicks
// const candlestickStyle = (color: string, delay: string) => css`
//   position: relative;
//   width: 6px;
//   height: 20px;
//   background-color: ${color};
//   margin: 0 4px;
//   animation: ${candlestickAnimation} 1s infinite ease-in-out;
//   animation-delay: ${delay};

//   &:before, &:after {
//     content: '';
//     position: absolute;
//     width: 2px;
//     background-color: ${color};
//     left: 50%;
//     transform: translateX(-50%);
//   }

//   &:before {
//     top: -6px; /* Length of upper wick */
//     height: 6px; /* Upper wick height */
//   }

//   &:after {
//     bottom: -6px; /* Length of lower wick */
//     height: 6px; /* Lower wick height */
//   }
// `;

// const Spinner: React.FC = () => {
//   return (
//     <div css={spinnerStyle}>
//       <div css={candlestickStyle('#4caf50', '0s')} /> {/* Green candlestick */}
//       <div css={candlestickStyle('#f44336', '-0.5s')} /> {/* Red candlestick */}
//       <div css={candlestickStyle('#4caf50', '-0.25s')} /> {/* Green candlestick */}
//     </div>
//   );
// };

// export default Spinner;

// -------bar spinner ----
// /** @jsxImportSource @emotion/react */
// import React from 'react';
// import { css, keyframes } from '@emotion/react';

// const candlestickAnimation = keyframes`
//   0% {
//     transform: scaleY(0.5);
//     opacity: 0.7;
//   }
//   50% {
//     transform: scaleY(1.5);
//     opacity: 1;
//   }
//   100% {
//     transform: scaleY(0.5);
//     opacity: 0.7;
//   }
// `;

// const spinnerStyle = css`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
// `;

// const candlestickStyle = (color: string) => css`
//   width: 4px;
//   height: 20px;
//   background-color: ${color};
//   margin: 0 2px;
//   animation: ${candlestickAnimation} 0.8s infinite ease-in-out;

//   &:nth-of-type(2) {
//     animation-delay: -0.4s;
//   }
//   &:nth-of-type(3) {
//     animation-delay: -0.2s;
//   }
// `;

// const Spinner: React.FC = () => {
//   return (
//     <div css={spinnerStyle}>
//       <div css={candlestickStyle('#4caf50')} /> {/* Green for a rising candlestick */}
//       <div css={candlestickStyle('#f44336')} /> {/* Red for a falling candlestick */}
//       <div css={candlestickStyle('#4caf50')} /> {/* Green for a rising candlestick */}
//     </div>
//   );
// };

// export default Spinner;


// --------- round spinner  --------

// /** @jsxImportSource @emotion/react */
// import React from 'react';
// import { css, useTheme } from '@emotion/react';
// import { CustomTheme } from '../../context/ThemeContext';

// const Spinner: React.FC = () => {
//   const theme = useTheme() as CustomTheme;

//   return (
//     <div css={spinnerContainerStyle}>
//       <div css={spinnerStyle(theme)} />
//     </div>
//   );
// };

// export default Spinner;

// const spinnerContainerStyle = css`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
// `;

// const spinnerStyle = (theme: CustomTheme) => css`
//   border: 4px solid ${theme.onSurfaceBorder};
//   border-top: 4px solid ${theme.primary};
//   border-radius: 50%;
//   width: 24px;
//   height: 24px;
//   animation: spin 0.8s linear infinite;

//   @keyframes spin {
//     0% {
//       transform: rotate(0deg);
//     }
//     100% {
//       transform: rotate(360deg);
//     }
//   }
// `;
