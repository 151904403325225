/** @jsxImportSource @emotion/react */
import { PropsWithChildren, forwardRef } from 'react';
import { StyledTypography } from './Typography.styles';
import { TypographyProps } from './Typography.types';

export const Typography = forwardRef<HTMLSpanElement, PropsWithChildren<TypographyProps>>(
  (props, ref) => {
    const { variant, className, children, ...rest } = props;

    return (
      <StyledTypography
        ref={ref}
        variant={variant}
        className={className}  // Pass className here
        {...rest}
      >
        {children}
      </StyledTypography>
    );
  }
);
