// src/components/Menu/LeftPaneMenu.tsx
/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    LayoutDashboard,
    NotebookText,
    CalendarDays,
    ChevronRight,
    ChevronDown,
    Layers,
    BarChart2,
    Activity,
    Users,
    BookOpen,
    Plus
} from 'lucide-react';
import { useTheme } from '../../context/ThemeContext';
import Logo from '../../assets/sdlogofull.svg';
import Modal from '../Modal/Modal';
import {
    leftPaneMenuStyle,
    menuHeaderStyle,
    menuLogoStyle,
    menuListStyle,
    menuItemStyle,
    menuLinkStyle,
    menuItemIconStyle,
    menuItemTextStyle,
    subMenuListStyle,
    subMenuItemStyle,
    menuChevronStyle,
    chevronIconStyle,
    addTradeButtonStyle,
} from './styles';

interface LeftPaneMenuProps {
    isCollapsed: boolean;
    toggleCollapse: () => void;
}

const LeftPaneMenu: React.FC<LeftPaneMenuProps> = ({ isCollapsed, toggleCollapse }) => {
    const location = useLocation();
    const { theme } = useTheme();
    const [openSubMenus, setOpenSubMenus] = useState<string[]>([]);
    const [isAddTradeModalOpen, setIsAddTradeModalOpen] = useState(false);


    const handleMenuClick = (menu: string) => {
        if (isCollapsed) {
            toggleCollapse();
        }

        if (openSubMenus.includes(menu)) {
            setOpenSubMenus(openSubMenus.filter((m) => m !== menu));
        } else {
            setOpenSubMenus([...openSubMenus, menu]);
        }
    };

    const isSubmenuActive = (paths: string[]) => {
        return paths.some(path => location.pathname.includes(path));
    };

    return (
        <div css={leftPaneMenuStyle(isCollapsed, theme)}>
            <div css={menuHeaderStyle(isCollapsed)}>
                <img src={Logo} alt="Logo" css={menuLogoStyle(isCollapsed)} />
            </div>

            {/* Add Trade Button */}
            <button
                css={addTradeButtonStyle(isCollapsed, theme)}
                onClick={() => setIsAddTradeModalOpen(true)}
                aria-label="Add Trade"
            >
                {/* Combined Icon and Text */}
                <span
                    css={{
                        display: 'flex',
                        alignItems: 'center',
                        opacity: isCollapsed ? 1 : 1,
                        transition: 'opacity 0.3s ease',
                    }}
                >
                    <Plus size={18} style={{ marginRight: isCollapsed ? '0' : '8px' }} /> {/* Single Plus Icon */}
                    {!isCollapsed && "Add Trade"} {/* Show Text only when expanded */}
                </span>
            </button>

            {/* Modal for Adding Trades */}
            <Modal
                isOpen={isAddTradeModalOpen}
                onClose={() => setIsAddTradeModalOpen(false)}
                title="Add New Trade"
                subtitle="Enter your trade details below."
            >
                <div>
                    {/* Placeholder for manual trade entry form */}
                    <p>Form content for adding a new trade goes here...</p>
                </div>
            </Modal>

            {/* Navigation Menus */}
            <ul css={menuListStyle}>
                {/* <li css={menuSectionHeadingStyle}>Main</li> */}

                <li css={menuItemStyle}>
                    <Link to="/dashboard" css={menuLinkStyle(isCollapsed, location.pathname === '/dashboard', theme)}>
                        <span css={menuItemIconStyle(isCollapsed)}><LayoutDashboard /></span>
                        {!isCollapsed && <span css={menuItemTextStyle}>Dashboard</span>}
                    </Link>
                </li>

                <li
                    css={menuItemStyle}
                    onClick={() => handleMenuClick('journal')}
                    className={openSubMenus.includes('journal') || isSubmenuActive(['/journal']) ? 'expanded' : ''}
                >
                    <div css={menuLinkStyle(isCollapsed, false, theme, isSubmenuActive(['/journal']))}>
                        <span css={menuItemIconStyle(isCollapsed)}><NotebookText /></span>
                        {!isCollapsed && <span css={menuItemTextStyle}>Trades</span>}
                        {!isCollapsed && (
                            <span css={menuChevronStyle}>
                                {openSubMenus.includes('journal') || isSubmenuActive(['/journal']) ? <ChevronDown css={chevronIconStyle} /> : <ChevronRight css={chevronIconStyle} />}
                            </span>
                        )}
                    </div>
                    {!isCollapsed && (
                        <ul css={subMenuListStyle(openSubMenus.includes('journal') || location.pathname.includes('/journal'))}>
                            <li css={subMenuItemStyle(location.pathname === '/journal', theme)}>
                                <Link to="/journal" css={menuLinkStyle(isCollapsed, location.pathname === '/journal', theme)}>
                                    Journal
                                </Link>
                            </li>
                            <li css={subMenuItemStyle(location.pathname === '/journal/add', theme)}>
                                <Link to="/journal/add" css={menuLinkStyle(isCollapsed, location.pathname === '/journal/add', theme)}>
                                    Analytics
                                </Link>
                            </li>
                        </ul>
                    )}
                </li>

                <li css={menuItemStyle}>
                    <Link to="/calendar" css={menuLinkStyle(isCollapsed, location.pathname === '/calendar', theme)}>
                        <span css={menuItemIconStyle(isCollapsed)}><CalendarDays /></span>
                        {!isCollapsed && <span css={menuItemTextStyle}>Calendar</span>}
                    </Link>
                </li>

                <li
                    css={menuItemStyle}
                    onClick={() => handleMenuClick('strategies')}
                    className={openSubMenus.includes('strategies') || isSubmenuActive(['/strategies']) ? 'expanded' : ''}
                >
                    <div css={menuLinkStyle(isCollapsed, false, theme, isSubmenuActive(['/strategies']))}>
                        <span css={menuItemIconStyle(isCollapsed)}><Layers /></span>
                        {!isCollapsed && <span css={menuItemTextStyle}>Strategies</span>}
                        {!isCollapsed && (
                            <span css={menuChevronStyle}>
                                {openSubMenus.includes('strategies') || isSubmenuActive(['/strategies']) ? <ChevronDown css={chevronIconStyle} /> : <ChevronRight css={chevronIconStyle} />}
                            </span>
                        )}
                    </div>
                    {!isCollapsed && (
                        <ul css={subMenuListStyle(openSubMenus.includes('strategies') || location.pathname.includes('/strategies'))}>
                            <li css={subMenuItemStyle(location.pathname === '/strategies/setups', theme)}>
                                <Link to="/strategies/setups" css={menuLinkStyle(isCollapsed, location.pathname === '/strategies/setups', theme)}>
                                    Setups
                                </Link>
                            </li>
                            <li css={subMenuItemStyle(location.pathname === '/strategies/tester', theme)}>
                                <Link to="/strategies/tester" css={menuLinkStyle(isCollapsed, location.pathname === '/strategies/tester', theme)}>
                                    Strategy Tester
                                </Link>
                            </li>
                        </ul>
                    )}
                </li>

                <li
                    css={menuItemStyle}
                    onClick={() => handleMenuClick('analytics')}
                    className={openSubMenus.includes('analytics') || isSubmenuActive(['/analytics']) ? 'expanded' : ''}
                >
                    <div css={menuLinkStyle(isCollapsed, false, theme, isSubmenuActive(['/analytics']))}>
                        <span css={menuItemIconStyle(isCollapsed)}><BarChart2 /></span>
                        {!isCollapsed && <span css={menuItemTextStyle}>Analytics</span>}
                        {!isCollapsed && (
                            <span css={menuChevronStyle}>
                                {openSubMenus.includes('analytics') || isSubmenuActive(['/analytics']) ? <ChevronDown css={chevronIconStyle} /> : <ChevronRight css={chevronIconStyle} />}
                            </span>
                        )}
                    </div>
                    {!isCollapsed && (
                        <ul css={subMenuListStyle(openSubMenus.includes('analytics') || location.pathname.includes('/analytics'))}>
                            <li css={subMenuItemStyle(location.pathname === '/analytics/performance', theme)}>
                                <Link to="/analytics/performance" css={menuLinkStyle(isCollapsed, location.pathname === '/analytics/performance', theme)}>
                                    Performance Metrics
                                </Link>
                            </li>
                            <li css={subMenuItemStyle(location.pathname === '/analytics/risk', theme)}>
                                <Link to="/analytics/risk" css={menuLinkStyle(isCollapsed, location.pathname === '/analytics/risk', theme)}>
                                    Risk Management
                                </Link>
                            </li>
                        </ul>
                    )}
                </li>

                <li
                    css={menuItemStyle}
                    onClick={() => handleMenuClick('market')}
                    className={openSubMenus.includes('market') || isSubmenuActive(['/market']) ? 'expanded' : ''}
                >
                    <div css={menuLinkStyle(isCollapsed, false, theme, isSubmenuActive(['/market']))}>
                        <span css={menuItemIconStyle(isCollapsed)}><Activity /></span>
                        {!isCollapsed && <span css={menuItemTextStyle}>Market Insights</span>}
                        {!isCollapsed && (
                            <span css={menuChevronStyle}>
                                {openSubMenus.includes('market') || isSubmenuActive(['/market']) ? <ChevronDown css={chevronIconStyle} /> : <ChevronRight css={chevronIconStyle} />}
                            </span>
                        )}
                    </div>
                    {!isCollapsed && (
                        <ul css={subMenuListStyle(openSubMenus.includes('market') || location.pathname.includes('/market'))}>
                            <li css={subMenuItemStyle(location.pathname === '/market/data', theme)}>
                                <Link to="/market/data" css={menuLinkStyle(isCollapsed, location.pathname === '/market/data', theme)}>
                                    Real-Time Data
                                </Link>
                            </li>
                            <li css={subMenuItemStyle(location.pathname === '/market/analysis', theme)}>
                                <Link to="/market/analysis" css={menuLinkStyle(isCollapsed, location.pathname === '/market/analysis', theme)}>
                                    Technical Analysis
                                </Link>
                            </li>
                        </ul>
                    )}
                </li>

                <li
                    css={menuItemStyle}
                    onClick={() => handleMenuClick('community')}
                    className={openSubMenus.includes('community') || isSubmenuActive(['/community']) ? 'expanded' : ''}
                >
                    <div css={menuLinkStyle(isCollapsed, false, theme, isSubmenuActive(['/community']))}>
                        <span css={menuItemIconStyle(isCollapsed)}><Users /></span>
                        {!isCollapsed && <span css={menuItemTextStyle}>Community</span>}
                        {!isCollapsed && (
                            <span css={menuChevronStyle}>
                                {openSubMenus.includes('community') || isSubmenuActive(['/community']) ? <ChevronDown css={chevronIconStyle} /> : <ChevronRight css={chevronIconStyle} />}
                            </span>
                        )}
                    </div>
                    {!isCollapsed && (
                        <ul css={subMenuListStyle(openSubMenus.includes('community') || location.pathname.includes('/community'))}>
                            <li css={subMenuItemStyle(location.pathname === '/community/social', theme)}>
                                <Link to="/community/social" css={menuLinkStyle(isCollapsed, location.pathname === '/community/social', theme)}>
                                    Social Trading
                                </Link>
                            </li>
                            <li css={subMenuItemStyle(location.pathname === '/community/forums', theme)}>
                                <Link to="/community/forums" css={menuLinkStyle(isCollapsed, location.pathname === '/community/forums', theme)}>
                                    Discussion Forums
                                </Link>
                            </li>
                        </ul>
                    )}
                </li>

                <li
                    css={menuItemStyle}
                    onClick={() => handleMenuClick('learning')}
                    className={openSubMenus.includes('learning') || isSubmenuActive(['/learning']) ? 'expanded' : ''}
                >
                    <div css={menuLinkStyle(isCollapsed, false, theme, isSubmenuActive(['/learning']))}>
                        <span css={menuItemIconStyle(isCollapsed)}><BookOpen /></span>
                        {!isCollapsed && <span css={menuItemTextStyle}>Learning</span>}
                        {!isCollapsed && (
                            <span css={menuChevronStyle}>
                                {openSubMenus.includes('learning') || isSubmenuActive(['/learning']) ? <ChevronDown css={chevronIconStyle} /> : <ChevronRight css={chevronIconStyle} />}
                            </span>
                        )}
                    </div>
                    {!isCollapsed && (
                        <ul css={subMenuListStyle(openSubMenus.includes('learning') || location.pathname.includes('/learning'))}>
                            <li css={subMenuItemStyle(location.pathname === '/learning/resources', theme)}>
                                <Link to="/learning/resources" css={menuLinkStyle(isCollapsed, location.pathname === '/learning/resources', theme)}>
                                    Resources
                                </Link>
                            </li>
                            <li css={subMenuItemStyle(location.pathname === '/learning/modules', theme)}>
                                <Link to="/learning/modules" css={menuLinkStyle(isCollapsed, location.pathname === '/learning/modules', theme)}>
                                    Interactive Modules
                                </Link>
                            </li>
                        </ul>
                    )}
                </li>

            </ul>
        </div>
    );
};

export default React.memo(LeftPaneMenu);
