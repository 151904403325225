// src/store/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserInfo {
    id: string;
    username: string | null;
    email: string;
    first_name: string;
    last_name: string;
    mobile_number: string | null;
    timezone: string;
    last_used_account_id: string | null;
}

interface UserState {
    userInfo: UserInfo | null;
    isUserInfoLoading: boolean;
}

const initialState: UserState = {
    userInfo: null,
    isUserInfoLoading: false,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserInfo: (state, action: PayloadAction<UserInfo | null>) => {
            state.userInfo = action.payload;
            state.isUserInfoLoading = false;
        },
        setUserInfoLoading: (state, action: PayloadAction<boolean>) => {
            state.isUserInfoLoading = action.payload;
        },
        resetUserInfo: () => initialState,
    },
});

export const { setUserInfo, setUserInfoLoading, resetUserInfo } = userSlice.actions;

export default userSlice.reducer;
