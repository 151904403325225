// src/themes/themes.ts
import { Theme } from '@emotion/react'; // Correct import

export const lightTheme: Theme = {
  mode: 'light', // Added mode property
  background: '#F5F5F5',
  surface: '#FFFFFF',
  primary: '#207cf6',
  primaryDark: '#1861c3',
  secondary: '#FB8C00',
  focusText: '#4a98ff',
  error: '#E53935',
  errorDark: '#B71C1C',
  onPrimary: '#FFFFFF',
  onSecondary: '#FFFFFF',
  onSurface: '#444444',
  onSurfaceDimmed: '#7b7b7b',
  titleText: '#666666',
  onError: '#FFFFFF',
  disabled: '#e0e0e0',
  fadedText: '#acacac',
  highlightedInput: '#2e83f5',
  onSurfaceBorder: '#dadada',
  tableGridLines: '#dadada',
  chipBackground: '#207cf6',
  holidayMarkerBackground: '#890505',
  gain: '#28a745',
  gainBackground: '#D4EDDA',
  loss: '#dc3545',
  lossBackground: '#F8D7DA',
  spacing: {
    small: '8px',
    medium: '16px',
    large: '24px',
  },
  typography: {
    fontSize: {
      tiny: '10px',
      small: '13px',
      medium: '14px',
      large: '16px',
    },
    fontFamily: 'Roboto, Open Sans, sans-serif',
    fontWeight: {
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
    },
    lineHeight: {
      normal: '1.5',
      relaxed: '1.75',
    },
  },
  borderRadius: '4px',
  chart: {
    bar: {
      positive: '#17994f',
      negative: '#c94244',
    },
    line: '#d19024',
    grid: '#dadada',
    background: '#FFFFFF',
  },
  tooltip: {
    backgroundColor: '#111111',
    color: '#FFFFFF',
  },
};

export const darkTheme: Theme = { // Changed to Theme
  mode: 'dark', // Added mode property
  background: '#0c0c1f', //'#121212',
  surface: '#141329', // '#212121',
  primary: '#90CAF9',
  primaryDark: '#5A9BD5',
  focusText: '#4a98ff',
  secondary: '#FFB74D',
  error: '#EF9A9A',
  errorDark: '#D32F2F',
  onPrimary: '#FFFFFF',
  onSecondary: '#FFFFFF',
  onSurface: '#d5d5d5',
  onSurfaceDimmed: '#9f9f9f',
  titleText: '#d5d5d580',
  onError: '#FFFFFF',
  disabled: '#2a2a2a',
  fadedText: '#54526a', //'#5c5c5c',
  highlightedInput: '#579dff',
  onSurfaceBorder: '#262539', //'#201f35', //'#393939',
  tableGridLines: '#262539', //'#2d2d2d',
  chipBackground: '#8c68ff',
  holidayMarkerBackground: '#890505',
  gain: '#28a745',
  gainBackground: '#1F3B29',
  loss: '#dc3545',
  lossBackground: '#3B1F1F',
  spacing: {
    small: '8px',
    medium: '16px',
    large: '24px',
  },
  typography: {
    fontSize: {
      tiny: '10px',
      small: '12px',
      medium: '14px',
      large: '16px',
    },
    fontFamily: 'Roboto, Open Sans, sans-serif',
    fontWeight: {
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
    },
    lineHeight: {
      normal: '1.5',
      relaxed: '1.75',
    },
  },
  borderRadius: '4px',
  chart: {
    bar: {
      positive: '#56a677',
      negative: '#ca3436',
    },
    line: '#e9b329',
    grid: '#262539', //'#393939',
    background: '#212121',
  },
  tooltip: {
    backgroundColor: '#000000',
    color: '#FFFFFF',
  },
};
