// src/store/dateRangeSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

// Define the state structure
interface DateRangeState {
  startDate: string | null;
  endDate: string | null;
}

const initialState: DateRangeState = {
  startDate: dayjs().startOf('year').format('YYYY-MM-DD'), // Default to Year to Date
  endDate: dayjs().format('YYYY-MM-DD'),
};

// Create the DateRange slice
const dateRangeSlice = createSlice({
  name: 'dateRange',
  initialState,
  reducers: {
    setDateRange: (state, action: PayloadAction<{ startDate: string | null; endDate: string | null }>) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    resetDateRange: (state) => {
      state.startDate = dayjs().startOf('year').format('YYYY-MM-DD');
      state.endDate = dayjs().format('YYYY-MM-DD');
    },
  },
});

// Export actions and reducer
export const { setDateRange, resetDateRange } = dateRangeSlice.actions;
export default dateRangeSlice.reducer;
