// src/components/Inputs/Dropdown.tsx
/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect } from 'react';
import { css, Theme } from '@emotion/react';
import { useTheme } from '../../context/ThemeContext';
import IconButton from '../IconButton/IconButton';
import { ChevronDown } from 'lucide-react';

interface DropdownProps {
    options: string[];
    onSelect: (value: string) => void;
    placeholder?: string;
    additionalStyles?: ReturnType<typeof css>;
    value?: string;
    selectedValueStyle?: ReturnType<typeof css>;
}

const Dropdown: React.FC<DropdownProps> = ({
    options,
    onSelect,
    placeholder,
    additionalStyles,
    value,
    selectedValueStyle,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { theme } = useTheme();
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionSelect = (option: string) => {
        onSelect(option);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div css={[dropdownWrapperStyle(theme), additionalStyles]} ref={dropdownRef}>
            <div
                css={[
                    dropdownHeaderStyle(theme),
                    !value && css`color: ${theme.fadedText};`,
                    selectedValueStyle,
                ]}
                onClick={toggleDropdown}
            >
                <span>{value || placeholder || 'Select an option'}</span>
                <IconButton
                    ariaLabel="Account list dropdown"
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown();
                    }}
                    size="tiny"
                >
                    <ChevronDown />
                </IconButton>
            </div>
            {isOpen && (
                <div css={dropdownListStyle(theme)}>
                    {options.map((option) => (
                        <div
                            key={option}
                            css={dropdownOptionStyle(theme)}
                            onClick={() => handleOptionSelect(option)}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Dropdown;

// Define Styles
const dropdownWrapperStyle = (theme: Theme) => css`
  position: relative;
  width: 100%;
  margin: ${theme.spacing.small} 0;
  display: flex;
`;

const dropdownHeaderStyle = (theme: Theme) => css`
  font-size: 14px;
  padding: ${theme.spacing.small};
  border: 1px solid ${theme.onSurfaceBorder};
  border-radius: ${theme.borderRadius};
  background-color: ${theme.surface};
  color: ${theme.onSurface};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  user-select: none;
`;

const dropdownListStyle = (theme: Theme) => css`
  font-size: 14px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid ${theme.onSurfaceBorder};
  border-radius: ${theme.borderRadius};
  background-color: ${theme.surface};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const dropdownOptionStyle = (theme: Theme) => css`
  padding: ${theme.spacing.small};
  cursor: pointer;
  color: ${theme.onSurface};
  &:hover {
    background-color: ${theme.primary}33;
  }
`;
