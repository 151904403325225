// src/context/AuthContext.tsx
import React, {
    createContext,
    useState,
    useContext,
    ReactNode,
    useCallback,
    useMemo,
    useEffect,
    useRef,
} from 'react';
import axios from 'axios';
import { AxiosService, sdAxiosInstance } from '../axios/axios';
import { handleApiError } from './helpers';
import { getCookieValue } from '../axios/helpers';
import { useDispatch } from 'react-redux';
import { setUserInfo, setUserInfoLoading } from '../store/userInfoSlice';
import { userinfo, UserInfoResponse } from '../services/userInfoService';
import { useNavigate } from 'react-router-dom';
import { persistor, store } from '../store/store';

interface AuthContextType {
    isAuthenticated: boolean;
    user: any;
    login: (
        username: string,
        password: string,
        _platform?: string
    ) => Promise<boolean>;
    logout: (skipApiCall?: boolean) => Promise<void>;
    error?: string;
    isLoading: boolean;
    logInReset: () => void;
    isInitialLoad: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

/**
 * AuthProvider component that wraps the application and provides authentication state.
 */
export const AuthProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [user, setUser] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const initialEffectRun = useRef(false);

    /**
     * Centralized logout function that handles user logout.
     */
    const logout = useCallback(
        async (skipApiCall = false) => {
            console.log('Logout function called');
            try {
                setIsLoading(true);

                // 1. Call the Logout API Endpoint First
                if (!skipApiCall) {
                    try {
                        // console.log('Calling logout API endpoint');
                        await AxiosService.post('/authn/logout', {});
                        // console.log('Logout API called successfully');
                    } catch (error) {
                        if (
                            axios.isAxiosError(error) &&
                            error.response?.status !== 401
                        ) {
                            // console.error('Logout API call failed:', error);
                            throw error;
                        }
                        // console.warn('Logout API responded with 401 Unauthorized or failed');
                    }
                }

                // 2. Clear Authentication State and Cookies
                setIsAuthenticated(false);
                setUser(null);
                sdAxiosInstance.resetAuthToken();
                setError(undefined);
                setIsInitialLoad(false);

                // Clear authentication cookies
                document.cookie =
                    'sd_xsrf_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                document.cookie =
                    '_sd_user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

                // 3. Reset Redux State and Purge Persisted State
                store.dispatch({ type: 'RESET_APP' });
                await persistor.flush();
                await persistor.purge();

                // Manually remove persisted state from localStorage
                localStorage.removeItem('persist:root');

                // 4. Redirect to Login Page
                navigate('/login', { replace: true });
            } catch (error) {
                console.error('Logout failed. Redirecting to login.', error);
                setIsAuthenticated(false);
                setUser(null);
                setError(undefined);
                setIsInitialLoad(false);

                // Ensure persisted state is cleared even if logout fails
                store.dispatch({ type: 'RESET_APP' });
                await persistor.flush();
                await persistor.purge();
                localStorage.removeItem('persist:root');

                navigate('/login', { replace: true });
            } finally {
                setIsLoading(false);
            }
        },
        [dispatch, navigate]
    );

    /**
     * Sets the logout handler so that Axios can trigger logout when needed.
     */
    useEffect(() => {
        console.log('Setting logout handler in AuthContext.');
        sdAxiosInstance.setLogoutHandler(logout);
    }, [logout]);

    /**
     * Revalidates user information by fetching the latest user info from the API.
     */
    const revalidateUserInfo = useCallback(async () => {
        if (!isAuthenticated || !user) return;

        try {
            dispatch(setUserInfoLoading(true));
            const userInfo: UserInfoResponse = await userinfo();
            dispatch(setUserInfo(userInfo));
            console.log('User info revalidated and updated:', userInfo);
        } catch (error) {
            console.error('Failed to revalidate user info:', error);
        } finally {
            dispatch(setUserInfoLoading(false));
        }
    }, [isAuthenticated, user, dispatch]);

    /**
     * Checks authentication status on initial load.
     */
    useEffect(() => {
        if (initialEffectRun.current) return;
        initialEffectRun.current = true;

        const checkAuthentication = async () => {
            const userCookie = getCookieValue('_sd_user');
            const xsrfToken = getCookieValue('sd_xsrf_token');

            if (!userCookie || !xsrfToken) {
                setUser(null);
                setIsAuthenticated(false);
                sdAxiosInstance.resetAuthToken();
                setIsInitialLoad(false);
                return;
            }

            try {
                const userData = JSON.parse(decodeURIComponent(userCookie || ''));
                setUser(userData);
                sdAxiosInstance.setXSRFToken(xsrfToken || '');
                setIsAuthenticated(true);
                setIsInitialLoad(false);

                await revalidateUserInfo();
            } catch (error) {
                console.error('Failed to parse user cookie:', error);
                setUser(null);
                setIsAuthenticated(false);
                sdAxiosInstance.resetAuthToken();
                setIsInitialLoad(false);
            }
        };

        checkAuthentication();
    }, [revalidateUserInfo]);

    /**
     * Handles user login by communicating with the API and setting authentication state.
     */
    const login = useCallback(
        async (
            username: string,
            password: string,
            _platform: string = 'web'
        ): Promise<boolean> => {
            try {
                setIsLoading(true);
                const response = await AxiosService.post('/authn/login', {
                    username,
                    password,
                    _platform,
                });

                if (response.status === 200) {
                    // For web platform, tokens and user data are sent in cookies
                    const xsrfToken = getCookieValue('sd_xsrf_token');
                    const userCookie = getCookieValue('_sd_user');
                    const userData = userCookie
                        ? JSON.parse(decodeURIComponent(userCookie))
                        : null;

                    if (userData && xsrfToken) {
                        setUser(userData);
                        setIsAuthenticated(true);
                        sdAxiosInstance.setXSRFToken(xsrfToken);
                        setError(undefined);
                        setIsInitialLoad(false);

                        dispatch(setUserInfoLoading(true));
                        const userInfo: UserInfoResponse = await userinfo();
                        dispatch(setUserInfo(userInfo));
                        return true;
                    } else {
                        // Unable to get user data or xsrf token from cookies
                        setError(
                            'Login failed: unable to retrieve authentication tokens'
                        );
                        setIsAuthenticated(false);
                        setUser(null);
                        return false;
                    }
                } else {
                    // Handle unexpected status codes
                    setError('Login failed: unexpected response from server');
                    setIsAuthenticated(false);
                    setUser(null);
                    return false;
                }
            } catch (error) {
                setIsAuthenticated(false);
                const apiError = handleApiError(error);
                setError(apiError.message || 'Invalid username or password');
            } finally {
                setIsLoading(false);
            }
            return false;
        },
        [dispatch]
    );

    /**
     * Resets login error and loading states.
     */
    const logInReset = useCallback(() => {
        setError(undefined);
        setIsLoading(false);
    }, []);

    /**
     * Memoizes the context value to prevent unnecessary re-renders.
     */
    const value = useMemo(
        () => ({
            isAuthenticated,
            user,
            login,
            logout,
            error,
            isLoading,
            logInReset,
            isInitialLoad,
        }),
        [
            isAuthenticated,
            user,
            login,
            logout,
            error,
            isLoading,
            logInReset,
            isInitialLoad,
        ]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

/**
 * Custom hook to access authentication context.
 *
 * @returns {AuthContextType} The authentication context.
 * @throws Will throw an error if used outside of AuthProvider.
 */
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
