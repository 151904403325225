// src/components/SDAppBar/SDAppBar.tsx
/** @jsxImportSource @emotion/react */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sun, Moon, Bell, User, Settings, LogOut, Menu as MenuIcon, Bolt, HelpCircle } from 'lucide-react';
import { css, Theme } from '@emotion/react';
import { useTheme } from '../../context/ThemeContext';
import styled from '@emotion/styled';
import { useAuth } from '../../context/AuthContext';
import IconButton from '../IconButton/IconButton';
import Badge from '../Badge/Badge';
import Dropdown from '../Inputs/Dropdown';
import { Tooltip } from '../Tooltip/Tooltip';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store/store';
import { setCurrentAccountId, fetchAccountsThunk } from '../../store/accountSlice';

interface SDAppBarProps {
    toggleCollapse: () => void;
    isCollapsed: boolean;
}

const SDAppBar: React.FC<SDAppBarProps> = ({ toggleCollapse, isCollapsed }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const navigate = useNavigate();
    const { logout, isAuthenticated } = useAuth();
    const { theme, toggleTheme } = useTheme();

    const dispatch = useDispatch<AppDispatch>();
    const { accounts, currentAccountId, error } = useSelector((state: RootState) => state.account);
    const userInfo = useSelector((state: RootState) => state.user.userInfo);
    const isUserInfoLoading = useSelector((state: RootState) => state.user.isUserInfoLoading);

    // Fetch accounts when the component mounts
    useEffect(() => {
        const loadAccounts = async () => {
            try {
                await dispatch(fetchAccountsThunk()).unwrap();
            } catch (error) {
                console.error('Failed to load accounts:', error);
            }
        };

        if (accounts.length === 0 && !isUserInfoLoading) {
            loadAccounts();
        }
    }, [dispatch, accounts.length, isUserInfoLoading]);

    // Set currentAccountId based on last_used_account_id after accounts and userInfo are loaded
    useEffect(() => {
        if (!currentAccountId && accounts.length > 0 && userInfo && !isUserInfoLoading) {
            let accountToSelect = accounts.find(account => account.account_id === userInfo.last_used_account_id) || accounts[0];
            if (accountToSelect) {
                dispatch(setCurrentAccountId(accountToSelect.account_id));
            }
        }
    }, [accounts, currentAccountId, userInfo, dispatch, isUserInfoLoading]);

    const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget), []);
    const handleMenuClose = useCallback(() => setAnchorEl(null), []);
    const handleThemeToggle = useCallback(() => toggleTheme(), [toggleTheme]);

    const handleAccountSelect = useCallback(
        (selectedName: string) => {
            const selectedAccount = accounts.find(account => account.account_name === selectedName);
            if (selectedAccount) dispatch(setCurrentAccountId(selectedAccount.account_id));
            handleMenuClose();
        },
        [accounts, dispatch, handleMenuClose]
    );

    const handleLogout = useCallback(async () => {
        if (isAuthenticated) {
            await logout();
            handleMenuClose();
            navigate('/login');
        }
    }, [logout, isAuthenticated, navigate, handleMenuClose]);

    // Helper function to handle navigation and menu close
    const handleMenuItemClick = useCallback(
        (path: string) => {
            navigate(path);
            handleMenuClose();
        },
        [navigate, handleMenuClose]
    );

    const initials = useMemo(() => userInfo?.username?.charAt(0).toUpperCase() || 'U', [userInfo]);

    // Move iconStyle inside the component
    const iconStyle = useCallback(
        () => css`
            color: ${theme.onSurface};
            transition: color 0.3s ease;
            &:hover {
                color: ${theme.highlightedInput};
            }
        `,
        [theme]
    );

    // Move menuButtonStyle inside the component
    const menuButtonStyle = useCallback(
        (): React.CSSProperties => ({
            color: theme.onSurface,
            transform: isCollapsed ? 'rotate(-90deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease, color 0.3s ease',
        }),
        [isCollapsed, theme]
    );

    return (
        <Header theme={theme}>
            <Toolbar>
                <IconButton onClick={toggleCollapse} ariaLabel="Toggle Menu" size="small" style={menuButtonStyle()}>
                    <MenuIcon css={iconStyle()} />
                </IconButton>
                <DropdownContainer>
                    <Dropdown
                        options={Array.isArray(accounts) ? accounts.map(account => account.account_name) : []}
                        value={
                            Array.isArray(accounts)
                                ? accounts.find(account => account.account_id === currentAccountId)?.account_name || ''
                                : ''
                        }
                        onSelect={handleAccountSelect}
                        placeholder="Select Account"
                        additionalStyles={css`
                            margin-left: 10px;
                            height: 32px;
                            border: 1px solid ${theme.highlightedInput};
                            border-radius: ${theme.borderRadius};
                        `}
                    />
                </DropdownContainer>
            </Toolbar>
            <IconContainer>
                <Tooltip text="Switch Theme" placement="bottom">
                    <IconButton onClick={handleThemeToggle} ariaLabel="Toggle Dark Mode" size="medium">
                        {theme.mode === 'light' ? <Moon css={iconStyle()} /> : <Sun css={iconStyle()} />}
                    </IconButton>
                </Tooltip>
                <Tooltip text="Notifications" placement="bottom">
                    <IconButton ariaLabel="Notifications" size="medium">
                        <Badge count={4}>
                            <Bell css={iconStyle()} />
                        </Badge>
                    </IconButton>
                </Tooltip>
                <IconButton onClick={handleMenuOpen} ariaLabel="Account Menu" size="medium">
                    <UserAvatar>{initials}</UserAvatar>
                </IconButton>
                <DropdownMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                    <UserInfoSection theme={theme}>
                        <UserAvatar>{initials}</UserAvatar>
                        <UserDetails>
                            <UserName>{userInfo?.username ?? 'User'}</UserName>
                            <UserEmail>{userInfo?.email}</UserEmail>
                        </UserDetails>
                    </UserInfoSection>
                    <EnhancedDivider theme={theme} />
                    <DropdownMenuItem theme={theme} onClick={() => handleMenuItemClick('/accounts')}>
                        <Bolt size={18} css={iconStyle()} /> Manage Accounts
                    </DropdownMenuItem>
                    <DropdownMenuItem theme={theme} onClick={() => handleMenuItemClick('/trades/add')}>
                        <Bolt size={18} css={iconStyle()} /> Add Trades
                    </DropdownMenuItem>
                    <EnhancedDivider theme={theme} />
                    <DropdownMenuItem theme={theme} onClick={() => handleMenuItemClick('/profile')}>
                        <User size={18} css={iconStyle()} /> Profile
                    </DropdownMenuItem>
                    <DropdownMenuItem theme={theme} onClick={() => handleMenuItemClick('/settings')}>
                        <Settings size={18} css={iconStyle()} /> Global Settings
                    </DropdownMenuItem>
                    <EnhancedDivider theme={theme} />
                    <DropdownMenuItem theme={theme} onClick={() => handleMenuItemClick('/help')}>
                        <HelpCircle size={18} css={iconStyle()} /> Help
                    </DropdownMenuItem>
                    <EnhancedDivider theme={theme} />
                    <DropdownMenuItem theme={theme} onClick={handleLogout}>
                        <LogOut size={18} css={iconStyle()} /> Logout
                    </DropdownMenuItem>
                </DropdownMenu>
            </IconContainer>
        </Header>
    );
};

export default React.memo(SDAppBar);

// Styled Components
const Header = styled.header<{ theme: Theme }>`
    padding: 0 16px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.surface};
    border-bottom: 1px solid ${({ theme }) => theme.onSurfaceBorder};
    z-index: 2000;
`;

const Toolbar = styled.div`
    display: flex;
    align-items: center;
`;

const DropdownContainer = styled.div`
    width: 200px;
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

const UserInfoSection = styled.div<{ theme: Theme }>`
    display: flex;
    align-items: center;
    padding: 16px;
`;

const UserAvatar = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #8884d8;
    color: white;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
`;

const UserDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const UserName = styled.span`
    font-weight: bold;
    font-size: 15px;
`;

const UserEmail = styled.span`
    font-size: 13px;
    color: #777;
`;

const DropdownMenuItem = styled.div<{ theme: Theme }>`
    padding: 12px 20px;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 0.8rem;
    color: ${({ theme }) => theme.onSurface};
    svg {
        color: ${({ theme }) => theme.onSurface};
        transition: color 0.3s ease;
    }
    &:hover {
        color: ${({ theme }) => theme.highlightedInput};
        svg {
            color: ${({ theme }) => theme.highlightedInput};
        }
    }
`;

const EnhancedDivider = styled.div<{ theme: Theme }>`
    border-top: 1px solid ${({ theme }) => theme.onSurfaceBorder}90;
    margin: 0px 16px;
`;
