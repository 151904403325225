// src/components/Layout/Layout.tsx
/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Outlet } from 'react-router-dom';
import LeftPaneMenu from '../Menu/LeftPaneMenu';
import SDAppBar from '../SDAppBar/SDAppBar';
import { useTheme } from '../../context/ThemeContext';
import { scrollbarStyles } from '../../styles/scrollbarStyles';

interface LayoutProps {
    showToolbar?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ showToolbar = true }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { theme } = useTheme();

    const toggleCollapse = () => setIsCollapsed(!isCollapsed);

    const layoutStyles = css`
    display: flex;
    height: 100vh;
    overflow: hidden;
    background-color: ${theme.background};

    .left-pane-menu {
      flex-shrink: 0;
      position: fixed;
      height: 100vh;
      width: ${isCollapsed ? '60px' : '248px'};
      z-index: 7001;
      transition: width 0.3s ease;
      background-color: ${theme.surface};
      border-right: 1px solid ${theme.onSurfaceBorder};
    }

    .content {
      flex-grow: 1;
      margin-left: ${isCollapsed ? '60px' : '248px'};
      transition: margin-left 0.3s ease;
      overflow-y: auto;
      height: 100vh;
      background-color: ${theme.background};
      color: ${theme.onSurface};
      ${scrollbarStyles(theme)}
    }

    .page-container {
      box-sizing: border-box;
    }

    .page-content {
      height: 100%;
      background-color: inherit;
    }
  `;

    return (
        <div css={layoutStyles}>
            <div className="left-pane-menu">
                <LeftPaneMenu isCollapsed={isCollapsed} toggleCollapse={toggleCollapse} />
            </div>
            <div className="content">
                {showToolbar && <SDAppBar toggleCollapse={toggleCollapse} isCollapsed={isCollapsed} />}
                <div className="page-container">
                    <div className="page-content">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Layout);
