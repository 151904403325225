// src/components/Auth/RequireAuth.tsx
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import Spinner from '../Spinner/Spinner';

interface RequireAuthProps {
    children: JSX.Element;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
    const { isAuthenticated, isInitialLoad } = useAuth();
    const location = useLocation();

    if (isInitialLoad) {
        return <Spinner />;
    }

    if (!isAuthenticated) {
        console.warn('User not authenticated, redirecting to login.');
        return <Navigate to="/login" state={{ from: location }} />;
    }
    return children;
};

export default RequireAuth;
