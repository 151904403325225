// src/components/Menu/styles.ts
/** @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { darken } from 'polished';

export const leftPaneMenuStyle = (isCollapsed: boolean, theme: Theme) => css`
  transition: width 0.3s ease;
  background-color: ${theme.surface};
  color: ${theme.onSurface};
  font-weight: 400;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: ${isCollapsed ? 'center' : 'start'};
  border-right: 1px solid ${theme.borderRadius};
`;

export const menuHeaderStyle = (isCollapsed: boolean) => css`
  height: ${isCollapsed ? '80px' : '80px'};
  display: flex;
  justify-content: ${isCollapsed ? 'center' : 'flex-start'};
  align-items: center;
  padding: 0 ${isCollapsed ? '0' : '10px'};
  transition: height 0.3s ease;
`;

export const menuLogoStyle = (isCollapsed: boolean) => css`
  width: ${isCollapsed ? '40px' : '100%'};
  padding: 20px 10px;
  margin-top: ${isCollapsed ? '10px' : '15px'};
  margin-bottom: ${isCollapsed ? '10px' : '15px'};
  box-sizing: border-box;
  transition: margin-bottom 0.3s ease, margin-top 0.3s ease;
`;

export const menuListStyle = css`
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  margin-top: 1.2rem;
  overflow-y: auto;
  max-height: calc(100vh - 64px);
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
`;

export const menuItemStyle = css`
  width: 100%;
  transition: margin 0.2s ease, transform 0.3s ease;
  margin-bottom: 0.5rem;
  user-select: none;
`;

export const menuLinkStyle = (isCollapsed: boolean, isActive: boolean, theme: Theme, isSubmenuActive?: boolean) => css`
  display: flex;
  align-items: center;
  justify-content: ${isCollapsed ? 'center' : 'flex-start'};
  padding: ${isCollapsed ? '8px 0' : '8px 16px'};
  color: ${isActive || isSubmenuActive ? theme.primary : theme.onSurface};
  text-decoration: none;
  cursor: pointer;
  border-radius: 6px;
  margin-left: ${isCollapsed ? '0' : '8px'};
  margin-right: ${isCollapsed ? '0' : '8px'};
  transition: color 0.3s ease;

  &:hover {
    color: ${darken(0.1, theme.primary)};
  }
`;

export const menuItemIconStyle = (isCollapsed: boolean) => css`
  margin-right: ${isCollapsed ? '0' : '0.38rem'};
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;

  ${isCollapsed ? '&:hover { transform: scale(1.1); }' : ''}
`;

export const menuItemTextStyle = css`
  flex: 1;
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  line-height: 1.5;
`;


export const subMenuListStyle = (isOpen: boolean) => css`
  list-style-type: none;
  padding-left: 0;
  max-height: ${isOpen ? '500px' : '0'};
  opacity: ${isOpen ? '1' : '0'};
  overflow: hidden;
  transition: 
    max-height 0.4s ease,
    opacity 0.3s ease,
    transform 0.3s ease;
    margin 0.3s ease;
  transform: ${isOpen ? 'translateY(0)' : 'translateY(-5px)'};
`;

export const subMenuItemStyle = (isActive: boolean, theme: Theme) => css`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 0.875rem;
  margin-bottom: 8px;
  color: ${isActive ? theme.primary : theme.onSurface};
  padding-left: 40px;
  margin-left: 8px;
  margin-right: 8px;
  padding-right: 8px;
  transition: color 0.3s ease;

  &:hover {
    color: ${darken(0.1, theme.primary)};
  }

  &::before, &::after {
    transition: all 0.3s ease;
  }

  &::before {
    content: '';
    position: absolute;
    left: 47px;
    top: 0;
    bottom: -10px;
    width: 1px;
    background-color: ${theme.onSurfaceBorder};
  }

  &::after {
    content: '';
    position: absolute;
    left: 45px;
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
    height: 5px;
    background-color: ${isActive ? theme.primary : theme.onSurfaceBorder};
    border-radius: 50%;
  }
`;

export const menuChevronStyle = css`
  margin-left: auto;
  display: flex;
  align-items: center;
  transition: transform 0.4s ease;
`;

export const chevronIconStyle = css`
  width: 14px;
  height: 14px;
  color: #565a62;
  transition: transform 0.3s ease;
`;

export const menuSectionHeadingStyle = css`
  font-size: 0.75rem;
  font-weight: 500;
  color: #8491b7;
  text-transform: uppercase;
  margin: 0.75rem 1.0rem;
  width: calc(100% - 32px);
`;

// Add Trade Button Style
export const addTradeButtonStyle = (isCollapsed: boolean, theme: Theme) => css`
  display: flex;
  align-items: center;
  justify-content: ${isCollapsed ? 'center' : 'flex-start'};
  background-color: ${theme.highlightedInput};
  color: ${theme.onPrimary};
  padding: ${isCollapsed ? '8px' : '12px 16px'};
  margin: 8px 16px;
  border-radius: 4px;
  height: 32px;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;
  font-size: ${isCollapsed ? '12px' : '14px'};
  width: ${isCollapsed ? '40px' : 'calc(100% - 32px)'};

  &:hover {
    background-color: ${theme.highlightedInput}CC;
  }

  svg {
    margin-right: ${isCollapsed ? '0' : '8px'};
  }
`;

// Separator Style
export const separatorStyle = (theme: Theme) => css`
  border: none;
  height: 1px;
  margin: 16px 0;
  background-color: ${theme.onSurfaceBorder};
  opacity: 0.5;
  width: calc(100% - 32px);
  margin-left: 16px;
`;