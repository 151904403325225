// src/App.tsx
import React from 'react';
import AppRoutes from './routes';
import GlobalStyles from './styles/GlobalStyles';
import { NotificationProvider } from './context/NotificationContext';
import ErrorBoundaryWrapper from './components/ErrorBoundary/ErrorBoundaryWrapper';

const App: React.FC = () => {
    return (
        <NotificationProvider>
            <GlobalStyles />
            <ErrorBoundaryWrapper>
                <div className="App">
                    <AppRoutes />
                </div>
            </ErrorBoundaryWrapper>
        </NotificationProvider>
    );
};

export default App;
