// src/context/ThemeContext.tsx
import React, { createContext, useState, useContext, ReactNode, useMemo, useCallback } from 'react';
import { ThemeProvider as EmotionThemeProvider, Theme } from '@emotion/react';
import { lightTheme, darkTheme } from '../themes';

interface ThemeContextType {
    theme: Theme;
    toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = (): ThemeContextType => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};

export const ThemeProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const [themeName, setThemeName] = useState<'light' | 'dark'>(() => {
        const savedTheme = localStorage.getItem('theme');
        return savedTheme === 'dark' ? 'dark' : 'light';
    });

    const currentTheme = useMemo<Theme>(() => (themeName === 'light' ? lightTheme : darkTheme), [themeName]);

    const toggleTheme = useCallback((): void => {
        const newThemeName = themeName === 'light' ? 'dark' : 'light';
        setThemeName(newThemeName);
        localStorage.setItem('theme', newThemeName);
    }, [themeName]);

    return (
        <ThemeContext.Provider value={{ theme: currentTheme, toggleTheme }}>
            <EmotionThemeProvider theme={currentTheme}>{children}</EmotionThemeProvider>
        </ThemeContext.Provider>
    );
};
