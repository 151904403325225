// src/components/LoadingFallback/LoadingFallback.tsx
/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import Spinner from '../Spinner/Spinner';

const loadingFallbackStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;  // Full viewport height to center the spinner
`;

const LoadingFallback: React.FC = () => {
    return (
        <div css={loadingFallbackStyle}>
            <Spinner />
        </div>
    );
};

export default LoadingFallback;
