// src/components/Tooltip/Tooltip.tsx
/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import { css, Theme, SerializedStyles } from '@emotion/react';
import { TooltipProps } from './Tooltip.types';
import { useTheme } from '../../context/ThemeContext';

export const Tooltip: FC<TooltipProps> = (props) => {
    const { text, placement = 'bottom', children, containerStyle, ...rest } = props;
    const { theme } = useTheme();

    return (
        <div css={[tooltipContainerStyle, containerStyle]} {...rest}>
            <span
                css={tooltipTextStyle(theme, placement)}
                data-tooltip={text}
                data-tooltip-pos={placement}
            >
                {children}
            </span>
        </div>
    );
};

const tooltipContainerStyle = css`
  position: relative;
  display: inline-block;
`;

const tooltipTextStyle = (theme: Theme, placement: string) => css`
  position: relative;
  display: inline-flex;

  &:hover:before,
  &:hover:after {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  &:before,
  &:after {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    transition: all 0.3s ease-out 0.4s;
    z-index: 5000;
  }

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }

  &:after {
    content: attr(data-tooltip);
    background-color: ${theme.tooltip.backgroundColor};
    color: ${theme.tooltip.color};
    padding: 6px 12px;
    border-radius: ${theme.borderRadius};
    white-space: nowrap;
    font-size: ${theme.typography.fontSize.small};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 5001;
  }

  /* Top-left placement */
  ${placement === 'topLeft' &&
    css`
    &:before {
      border-top: 6px solid ${theme.tooltip.backgroundColor};
      bottom: 100%;
      left: 8px;
    }
    &:after {
      bottom: calc(100% + 6px);
      left: 0;
    }
  `}

  /* Top-right placement */
  ${placement === 'topRight' &&
    css`
    &:before {
      border-top: 6px solid ${theme.tooltip.backgroundColor};
      bottom: 100%;
      right: 8px;
    }
    &:after {
      bottom: calc(100% + 6px);
      right: 0;
    }
  `}

  /* Bottom-left placement */
  ${placement === 'bottomLeft' &&
    css`
    &:before {
      border-bottom: 6px solid ${theme.tooltip.backgroundColor};
      top: 100%;
      left: 8px;
    }
    &:after {
      top: calc(100% + 6px);
      left: 0;
    }
  `}

  /* Bottom-right placement */
  ${placement === 'bottomRight' &&
    css`
    &:before {
      border-bottom: 6px solid ${theme.tooltip.backgroundColor};
      top: 100%;
      right: 8px;
    }
    &:after {
      top: calc(100% + 6px);
      right: 0;
    }
  `}

  /* Default placements for top, bottom, left, right */
  ${placement === 'top' &&
    css`
    &:before {
      border-top: 6px solid ${theme.tooltip.backgroundColor};
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
    &:after {
      bottom: calc(100% + 6px);
      left: 50%;
      transform: translateX(-50%);
    }
  `}

  ${placement === 'bottom' &&
    css`
    &:before {
      border-bottom: 6px solid ${theme.tooltip.backgroundColor};
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
    &:after {
      top: calc(100% + 6px);
      left: 50%;
      transform: translateX(-50%);
    }
  `}

  ${placement === 'left' &&
    css`
    &:before {
      border-left: 6px solid ${theme.tooltip.backgroundColor};
      right: 100%;
      top: 50%;
      transform: translate(0, -50%);
    }
    &:after {
      right: calc(100% + 6px);
      top: 50%;
      transform: translate(0, -50%);
    }
  `}

  ${placement === 'right' &&
    css`
    &:before {
      border-right: 6px solid ${theme.tooltip.backgroundColor};
      left: 100%;
      top: 50%;
      transform: translate(0, -50%);
    }
    &:after {
      left: calc(100% + 6px);
      top: 50%;
      transform: translate(0, -50%);
    }
  `}
`;
