// src/routes.tsx
import React, { lazy, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom'; // Removed BrowserRouter import
import Layout from './components/Layout/Layout';
import RequireAuth from './components/Auth/RequireAuth';
import LoadingFallback from './components/LoadingFallback/LoadingFallback';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';

// Lazy load pages
// Dashboard
const DashboardPage = lazy(() => import('./pages/DashboardPage/DashboardPage'));
// Trade Logs
const TradeJournalPage = lazy(() => import('./pages/TradeJournalPage/TradeJournalPage'));
const TradeAnalyticsPage = lazy(() => import('./pages/TradeAnalyticsPage/TradeAnalyticsPage')); // Page not done yet
// Calendar
const CalendarPage = lazy(() => import('./pages/CalendarPage/CalendarPage'));
const StrategiesPage = lazy(() => import('./pages/StrategiesPage/StrategiesPage')); // Page not done yet
const StrategyTesterPage = lazy(() => import('./pages/StrategyTesterPage/StrategyTesterPage')); // Page not done yet
const PerformanceMetricsPage = lazy(() => import('./pages/PerformanceMetricsPage/PerformanceMetricsPage')); // Page not done yet
const RiskManagementPage = lazy(() => import('./pages/RiskManagementPage/RiskManagementPage')); // Page not done yet
const RealTimeDataPage = lazy(() => import('./pages/RealTimeDataPage/RealTimeDataPage')); // Page not done yet
const TechnicalAnalysisPage = lazy(() => import('./pages/TechnicalAnalysisPage/TechnicalAnalysisPage')); // Page not done yet
const SocialTradingPage = lazy(() => import('./pages/SocialTradingPage/SocialTradingPage')); // Page not done yet
const DiscussionForumsPage = lazy(() => import('./pages/DiscussionForumsPage/DiscussionForumsPage')); // Page not done yet
const LearningResourcesPage = lazy(() => import('./pages/LearningResourcesPage/LearningResourcesPage')); // Page not done yet
const InteractiveModulesPage = lazy(() => import('./pages/InteractiveModulesPage/InteractiveModulesPage')); // Page not done yet
// Accounts
const ListAccountsPage = lazy(() => import('./pages/Accounts/ListAccountsPage'));
const AddAccountPage = lazy(() => import('./pages/Accounts/AddAccountPage'));
// Settings
const SettingsPage = lazy(() => import('./pages/SettingsPage/SettingsPage'));
// Auth
const LoginPage = lazy(() => import('./pages/Auth/Login/LoginPage'));
const SignUpPage = lazy(() => import('./pages/Auth/SignUp/SignUpPage'));
const LogoutPage = lazy(() => import('./pages/Auth/Logout/LogoutPage'));
// Add Trades + Import Export
const AddTradesPage = lazy(() => import('./pages/Trades/AddTradesPage'));

const AppRoutes: React.FC = () => (
    <Suspense fallback={<LoadingFallback />}>
        <Routes>
            {/* Default Route: Redirect '/' to '/login' */}
            <Route path="/" element={<Navigate to="/login" replace />} />

            {/* Auth Routes */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/logout" element={<LogoutPage />} />

            {/* Protected Routes with Layout */}
            <Route element={<Layout showToolbar={true} />}>
                <Route
                    path="/dashboard"
                    element={
                        <RequireAuth>
                            <DashboardPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/journal"
                    element={
                        <RequireAuth>
                            <TradeJournalPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/journal/add"
                    element={
                        <RequireAuth>
                            <TradeAnalyticsPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/calendar"
                    element={
                        <RequireAuth>
                            <CalendarPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/strategies/setups"
                    element={
                        <RequireAuth>
                            <StrategiesPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/strategies/tester"
                    element={
                        <RequireAuth>
                            <StrategyTesterPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/analytics/performance"
                    element={
                        <RequireAuth>
                            <PerformanceMetricsPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/analytics/risk"
                    element={
                        <RequireAuth>
                            <RiskManagementPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/market/data"
                    element={
                        <RequireAuth>
                            <RealTimeDataPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/market/analysis"
                    element={
                        <RequireAuth>
                            <TechnicalAnalysisPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/community/social"
                    element={
                        <RequireAuth>
                            <SocialTradingPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/community/forums"
                    element={
                        <RequireAuth>
                            <DiscussionForumsPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/learning/resources"
                    element={
                        <RequireAuth>
                            <LearningResourcesPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/learning/modules"
                    element={
                        <RequireAuth>
                            <InteractiveModulesPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/settings"
                    element={
                        <RequireAuth>
                            <SettingsPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/accounts"
                    element={
                        <RequireAuth>
                            <ListAccountsPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/accounts/add"
                    element={
                        <RequireAuth>
                            <AddAccountPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/trades/add"
                    element={
                        <RequireAuth>
                            <AddTradesPage />
                        </RequireAuth>
                    }
                />
            </Route>

            {/* Catch-all Route for Undefined Paths */}
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    </Suspense>
);

export default AppRoutes;
