// src/store/accountSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Account, fetchAccounts, createAccount, updateAccount } from '../services/accountService';

// Define Account state structure
interface AccountState {
    accounts: Account[];
    currentAccountId: string | null;
    isAccountLoading: boolean;
    isCreating: boolean;
    isUpdating: boolean;
    error: string | null;
}

// Initial state
const initialState: AccountState = {
    accounts: [],
    currentAccountId: null,
    isAccountLoading: false,
    isCreating: false,
    isUpdating: false,
    error: null,
};

// Async thunk for fetching accounts
export const fetchAccountsThunk = createAsyncThunk<Account[], void, { rejectValue: string }>(
    'account/fetchAccounts',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchAccounts();
            return response.accounts;
        } catch (error: any) {
            console.error('Failed to fetch accounts:', error);
            return rejectWithValue(error.message || 'Failed to fetch accounts');
        }
    }
);

// Async thunk for creating an account
export const createAccountThunk = createAsyncThunk<Account, Partial<Account>, { rejectValue: string }>(
    'account/createAccount',
    async (accountData, { rejectWithValue }) => {
        try {
            const newAccount = await createAccount(accountData);
            return newAccount;
        } catch (error: any) {
            console.error('Failed to create account:', error);
            return rejectWithValue(error.message || 'Failed to create account');
        }
    }
);

// Async thunk for updating an account
export const updateAccountThunk = createAsyncThunk<Account, { accountId: string; accountData: Partial<Account> }, { rejectValue: string }>(
    'account/updateAccount',
    async ({ accountId, accountData }, { rejectWithValue }) => {
        try {
            const updatedAccount = await updateAccount(accountId, accountData);
            return updatedAccount;
        } catch (error: any) {
            console.error(`Failed to update account ${accountId}:`, error);
            return rejectWithValue(error.message || 'Failed to update account');
        }
    }
);

// Create the Account slice
const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setCurrentAccountId: (state, action: PayloadAction<string | null>) => {
            state.currentAccountId = action.payload;
        },
        resetAccountState: () => initialState,
    },
    extraReducers: (builder) => {
        // Handle fetchAccountsThunk
        builder.addCase(fetchAccountsThunk.pending, (state) => {
            state.isAccountLoading = true;
            state.error = null;
        });
        builder.addCase(fetchAccountsThunk.fulfilled, (state, action: PayloadAction<Account[]>) => {
            state.accounts = action.payload;
            state.isAccountLoading = false;
        });
        builder.addCase(fetchAccountsThunk.rejected, (state, action) => {
            state.isAccountLoading = false;
            state.error = action.payload || 'Unable to fetch accounts';
        });

        // Handle createAccountThunk
        builder.addCase(createAccountThunk.pending, (state) => {
            state.isCreating = true;
            state.error = null;
        });
        builder.addCase(createAccountThunk.fulfilled, (state, action: PayloadAction<Account>) => {
            state.accounts.push(action.payload);
            state.isCreating = false;
        });
        builder.addCase(createAccountThunk.rejected, (state, action) => {
            state.isCreating = false;
            state.error = action.payload || 'Unable to create account';
        });

        // Handle updateAccountThunk
        builder.addCase(updateAccountThunk.pending, (state) => {
            state.isUpdating = true;
            state.error = null;
        });
        builder.addCase(updateAccountThunk.fulfilled, (state, action: PayloadAction<Account>) => {
            const index = state.accounts.findIndex(acc => acc.account_id === action.payload.account_id);
            if (index !== -1) {
                state.accounts[index] = action.payload;
            }
            state.isUpdating = false;
        });
        builder.addCase(updateAccountThunk.rejected, (state, action) => {
            state.isUpdating = false;
            state.error = action.payload || 'Unable to update account';
        });
    },
});

// Export synchronous actions
export const { setCurrentAccountId, resetAccountState } = accountSlice.actions;

// Export reducer
export default accountSlice.reducer;
