// src/styles/GlobalStyles.tsx
import { css, Global, useTheme } from '@emotion/react';

const GlobalStyles = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
        
        html {
          font-size: 16px;
        }

        body {
          margin: 0;
          padding: 0;
          background-color: ${theme.background};
          color: ${theme.onSurface};
          font-family: ${theme.typography.fontFamily};
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        h1, h2, h3, h4, h5, h6, p, a, span, div {
          font-family: ${theme.typography.fontFamily};
        }

        .surface {
          background-color: ${theme.surface};
          color: ${theme.onSurface};
        }

        .primary {
          background-color: ${theme.primary};
          color: ${theme.onPrimary};
        }

        .secondary {
          background-color: ${theme.secondary};
          color: ${theme.onSecondary};
        }

        code {
          font-family: source-code-pro, 'Roboto', 'Open Sans', sans-serif;
        }
      `}
    />
  );
};

export default GlobalStyles;
