// src/context/NotificationContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface NotificationContextProps {
  showMessage: (message: string, type: 'success' | 'error' | 'info') => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [message, setMessage] = useState<{ text: string; type: string } | null>(null);

  const showMessage = (msg: string, type: 'success' | 'error' | 'info') => {
    setMessage({ text: msg, type });
    setTimeout(() => setMessage(null), 3000); // Auto-hide after 3 seconds
  };

  return (
    <NotificationContext.Provider value={{ showMessage }}>
      {children}
      {message && (
        <div style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          padding: '10px 20px',
          backgroundColor: message.type === 'error' ? 'red' : message.type === 'success' ? 'green' : 'blue',
          color: 'white',
          borderRadius: '5px',
          zIndex: 1000,
        }}>
          {message.text}
        </div>
      )}
    </NotificationContext.Provider>
  );
};
