// src/components/Typography/Typography.styles.tsx
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { css, Theme } from '@emotion/react';
import { TypographyProps } from './Typography.types';


const variants = (theme: Theme) => ({
  h1: css`
    font-weight: 500;
    font-size: 42px;
    line-height: 110%;
    letter-spacing: -1.5px;
  `,
  h2: css`
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.5px;
  `,
  h3: css`
    font-weight: 500;
    font-size: 26px;
    line-height: 116.7%;
  `,
  h4: css`
    font-weight: 500;
    font-size: 22px;
    line-height: 123.5%;
    letter-spacing: 0.25px;
  `,
  h4_semibold: css`
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
  `,
  h5: css`
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
  `,
  h5_medium: css`
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
  `,
  h5_semibold: css`
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
  `,
  h6: css`
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
  `,
  h6_medium_t1: css`
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
  `,
  subtitle_1: css`
    font-weight: 600;
    font-size: 18px;
    line-height: 125%;
  `,
  subtitle_1_medium: css`
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
  `,
  subtitle_2: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.15px;
  `,
  subtitle_2_medium: css`
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
  `,
  subtitle_2_pagetitle: css`
    font-weight: 500;
    font-size: 17px;
    line-height: 140%;
  `,
  subtitle_3: css`
    font-weight: 600;
    font-size: 14px;
    line-height: 125%;
  `,
  subtitle_3_medium: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
  `,
  subtitle_3_extra_bold: css`
    font-weight: 800;
    font-size: 14px;
    line-height: 140%;
  `,
  subtitle_4: css`
    font-weight: 600;
    font-size: 12px;
    line-height: 125%;
  `,
  subtitle_4_medium: css`
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
  `,
  subtitle_4_extra_bold: css`
    font-weight: 800;
    font-size: 12px;
    line-height: 140%;
  `,
  subtitle_5: css`
    font-weight: 600;
    font-size: 13px;
    line-height: 125%;
  `,
  subtitle_5_medium: css`
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
  `,
  body_1: css`
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.15px;
  `,
  body_2: css`
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  `,
  body_3: css`
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
  `,
  button_large: css`
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
  `,
  button_medium: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
  `,
  button_small: css`
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
  `,
  button_xtra_small: css`
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
  `,
  caption: css`
    font-weight: 400;
    font-size: 12px;
    line-height: 166%;
    letter-spacing: 0.4px;
  `,
  overline: css`
    font-weight: 600;
    font-size: 12px;
    line-height: 266%;
  `,
  avatar_letter: css`
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.14px;
  `,
  input_label: css`
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.15px;
  `,
  helper_text: css`
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  `,
  input_text: css`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  `,
  input_text_small: css`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  `,
  tooltip: css`
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
  `,
  chip_medium: css`
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  `,
  chip_small: css`
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
  `,
  paragraph_1_medium: css`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  `,
  paragraph_2_medium: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  `,
  paragraph_3_medium: css`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  `,
  job_label: css`
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
  `,
  job_label_l: css`
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  `,
  hero: css`
    font-weight: 900;
    font-size: 64px;
  `,
  h1_regular: css`
    font-weight: 400;
    font-size: 38px;
    line-height: 55px;
  `,
  h1_medium: css`
    font-weight: 500;
    font-size: 38px;
    line-height: 55px;
  `,
  h1_semi_bold: css`
    font-weight: 600;
    font-size: 38px;
    line-height: 55px;
  `,
  h1_bold: css`
    font-weight: 700;
    font-size: 38px;
    line-height: 55px;
  `,
  h2_regular: css`
    font-weight: 400;
    font-size: 34px;
    line-height: 50px;
  `,
  h2_medium: css`
    font-weight: 500;
    font-size: 34px;
    line-height: 50px;
  `,
  h2_semi_bold: css`
    font-weight: 600;
    font-size: 34px;
    line-height: 50px;
  `,
  h2_bold: css`
    font-weight: 700;
    font-size: 34px;
    line-height: 50px;
  `,
  h3_regular: css`
    font-size: 28px;
    font-weight: 400;
    line-height: 130%;
  `,
  h3_medium: css`
    font-size: 28px;
    font-weight: 500;
    line-height: 130%;
  `,
  h3_semi_bold: css`
    font-size: 28px;
    font-weight: 600;
    line-height: 130%;
  `,
  h3_bold: css`
    font-size: 28px;
    font-weight: 700;
    line-height: 130%;
  `,
  h4_regular: css`
    font-size: 21px;
    font-weight: 400;
    line-height: 130%;
  `,
  h4_medium: css`
    font-size: 21px;
    font-weight: 500;
    line-height: 130%;
  `,
  h4_semi_bold: css`
    font-size: 21px;
    font-weight: 600;
    line-height: 130%;
  `,
  h4_bold: css`
    font-size: 21px;
    font-weight: 700;
    line-height: 130%;
  `,
  h5_regular: css`
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
  `,
  wpm_h5_medium: css`
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
  `,
  h5_semi_bold: css`
    font-size: 15px;
    font-weight: 600;
    line-height: 26px;
  `,
  h5_bold: css`
    font-size: 15px;
    font-weight: 700;
    line-height: 26px;
  `,
  h6_regular: css`
    font-weight: 400;
    font-size: 13px;
    line-height: 130%;
  `,
  h6_medium: css`
    font-weight: 400;
    font-size: 13px;
    line-height: 20.8px;
  `,
  h6_semi_bold: css`
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
  `,
  h6_bold: css`
    font-weight: 700;
    font-size: 13px;
    line-height: 130%;
  `,
  b1_medium: css`
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
  `,
  b1_semibold: css`
    font-size: 13px;
    font-weight: 600;
    line-height: 24.51px;
  `,
  b2_regular: css`
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
  `,
  b2_medium: css`
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
  `,
  b2_semibold: css`
    font-weight: 600;
    font-size: 13px;
    line-height: normal;
  `,
  b3_regular: css`
    font-size: 10px;
    font-weight: 400;
    line-height: 21px;
  `,
  b3_medium: css`
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
  `,
  b3_semibold: css`
    font-size: 10px;
    font-weight: 600;
    line-height: 21px;
  `,
  table_header: css`
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
  `,
  table_data: css`
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
  `,
  p1_regular: css`
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  `,
  p1_medium: css`
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
  `,
  p2_semi_bold: css`
    font-size: 10px;
    font-weight: 600;
    line-height: normal;
  `,
  p2_medium: css`
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
  `,
  p3_regular: css`
    font-size: 8px;
    font-weight: 400;
    line-height: normal;
  `,
  p3_medium: css`
    font-size: 8px;
    font-weight: 500;
    line-height: normal;
  `,
  p3_semi_bold: css`
    font-size: 8px;
    font-weight: 600;
    line-height: normal;
  `,
});

export const StyledTypography = styled.span<TypographyProps>`
  ${({ theme, variant }) => variant && variants(theme)[variant]}
  color: ${({ color }) => color ?? 'inherit'};
  transition: all 0.3s;
`;
