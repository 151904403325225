// src/context/helpers.ts
import axios from 'axios';
import has from 'lodash/has';

import { MessageType } from './handlers.types';

export function handleApiError(error: unknown): MessageType {
  if (axios.isAxiosError(error)) {
    return {
      message: has(error.response?.data, 'message')
        ? (error.response?.data as MessageType).message
        : 'Something went wrong! Please try again later.',
    };
  }

  return { message: error instanceof Error ? error.message : 'UNKNOWN ERROR' };
}
