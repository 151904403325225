// src/components/Modal/Modal.tsx

/** @jsxImportSource @emotion/react */
import React from 'react';
import { Theme } from '@emotion/react';
import { useTheme } from '../../context/ThemeContext';
import { ModalProps } from './Modal.types';
import { modalOverlay, modalContainer, modalHeader, modalTitle, modalSubtitle, modalBody, modalFooter, closeButtonStyle } from './Modal.styles';
import { Typography } from '../Typography/Typography';
import { useEffect } from 'react';

const Modal: React.FC<ModalProps> = ({
    isOpen,
    onClose,
    title,
    subtitle,
    children,
    footerButtons,
    closeOnOverlayClick = true,
}) => {
    const { theme } = useTheme();

    // Prevent scrolling when modal is open
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    // Handle overlay click
    const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (closeOnOverlayClick && e.target === e.currentTarget) {
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <div css={modalOverlay(theme)} onClick={handleOverlayClick}>
            <div css={modalContainer(theme)}>
                {/* Modal Header */}
                <div css={modalHeader(theme)}>
                    <div>
                        {title && (
                            <Typography variant="h5" css={modalTitle(theme)}>
                                {title}
                            </Typography>
                        )}
                        {subtitle && (
                            <Typography variant="subtitle_1" css={modalSubtitle(theme)}>
                                {subtitle}
                            </Typography>
                        )}
                    </div>
                    <button css={closeButtonStyle(theme)} onClick={onClose} aria-label="Close Modal">
                        &times;
                    </button>
                </div>

                {/* Modal Body */}
                <div css={modalBody(theme)}>{children}</div>

                {/* Modal Footer */}
                {footerButtons && <div css={modalFooter(theme)}>{footerButtons}</div>}
            </div>
        </div>
    );
};

export default Modal;
