// src/axios/axios.ts
import axios, {
    AxiosInstance,
    AxiosError,
    InternalAxiosRequestConfig,
} from 'axios';
import axiosRetry from 'axios-retry';
import { getCookieValue } from './helpers';
import { publicEndpoints } from '../config/publicEndpoints';

/**
 * Extends the Axios request configuration to include a retry flag.
 */
interface ExtendedAxiosRequestConfig extends InternalAxiosRequestConfig {
    _retry?: boolean;
}

/**
 * Custom error class for API-related errors.
 */
class ApiError extends Error {
    constructor(
        message: string,
        public statusCode?: number,
        public details?: any
    ) {
        super(message);
        this.name = 'ApiError';
    }
}

/**
 * Logger service for standardized logging.
 */
const logger = {
    log: (message: string, context?: any) => {
        console.log(`[Log]: ${message}`, context);
    },
    error: (message: string, context?: any) => {
        console.error(`[Error]: ${message}`, context);
    },
};

/**
 * Implements a simple circuit breaker pattern to prevent overwhelming the API.
 */
class CircuitBreaker {
    private failureCount = 0;
    private successThreshold = 2;
    private failureThreshold = 5;
    private state: 'CLOSED' | 'OPEN' | 'HALF-OPEN' = 'CLOSED';

    public isRequestAllowed(): boolean {
        return this.state === 'CLOSED' || this.state === 'HALF-OPEN';
    }

    onSuccess() {
        if (this.state === 'HALF-OPEN') {
            this.successThreshold--;
            if (this.successThreshold <= 0) this.state = 'CLOSED';
        }
        this.failureCount = 0;
    }

    onFailure() {
        this.failureCount++;
        if (this.failureCount >= this.failureThreshold) {
            this.state = 'OPEN';
            setTimeout(() => (this.state = 'HALF-OPEN'), 30000); // 30 seconds cooldown
        }
    }
}

/**
 * Manages request interceptors for middleware-like support.
 */
class InterceptorManager {
    private interceptors: Array<
        (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig
    > = [];

    addInterceptor(
        interceptor: (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig
    ) {
        this.interceptors.push(interceptor);
    }

    applyInterceptors(config: InternalAxiosRequestConfig) {
        this.interceptors.forEach((interceptor) => {
            config = interceptor(config);
        });
        return config;
    }
}

/**
 * SDAxios class encapsulates Axios with additional features like retry logic, circuit breaker,
 * and interceptors for token management.
 */
export class SDAxios {
    public instance: AxiosInstance;
    private baseURL?: string;
    private timeout: number;
    private xsrfToken: string;
    private retries: number;
    private isRefreshing = false;
    private refreshTokenPromise?: Promise<string | undefined>;
    private circuitBreaker = new CircuitBreaker();
    private requestInterceptorManager = new InterceptorManager();
    private logoutHandler?: (skipApiCall?: boolean) => Promise<void>;

    constructor(
        baseURL?: string,
        timeout = 30000,
        retries = 2,
        customHeaders: Record<string, string> = {}
    ) {
        this.baseURL =
            baseURL ||
            process.env.REACT_APP_API_BASE_URL ||
            'http://localhost:3000/local';
        this.timeout = timeout;
        this.retries = retries;
        this.xsrfToken = getCookieValue('sd_xsrf_token') || '';
        this.instance = this._initializeInstance();

        Object.entries(customHeaders).forEach(([key, value]) => {
            this.setCustomHeader(key, value);
        });
    }

    /**
     * Sets the logout handler function.
     */
    public setLogoutHandler(
        logoutHandler: (skipApiCall?: boolean) => Promise<void>
    ) {
        this.logoutHandler = logoutHandler;
    }

    /**
     * Initializes the Axios instance with base configurations.
     */
    private _initializeInstance(): AxiosInstance {
        const instance = axios.create({
            baseURL: this.baseURL,
            timeout: this.timeout,
            withCredentials: true,
        });

        logger.log('Axios Base URL:', this.baseURL);

        this._attachRetryLogic(instance);
        return this._attachInterceptors(instance);
    }

    /**
     * Attaches retry logic to the Axios instance using axios-retry.
     */
    private _attachRetryLogic(instance: AxiosInstance): void {
        axiosRetry(instance, {
            retries: this.retries,
            retryDelay: axiosRetry.exponentialDelay,
            retryCondition: (error: AxiosError) => {
                if (!this.circuitBreaker.isRequestAllowed()) {
                    return false;
                }

                const retryStatusCodes = [408, 500, 502, 503, 504, 429];
                if (error.response && retryStatusCodes.includes(error.response.status)) {
                    this.circuitBreaker.onFailure();
                } else {
                    this.circuitBreaker.onSuccess();
                }

                return !!(
                    error.response && retryStatusCodes.includes(error.response.status)
                );
            },
        });
    }

    /**
     * Attaches request and response interceptors to the Axios instance.
     */
    private _attachInterceptors(instance: AxiosInstance): AxiosInstance {
        instance.interceptors.request.use(
            async (config) => {
                const isPublicEndpoint = this._isPublicEndpoint(config.url);
                const isRefreshEndpoint = this._isRefreshEndpoint(config.url);

                // Retrieve essential cookies
                const xsrfToken = getCookieValue('sd_xsrf_token');
                const sdUser = getCookieValue('_sd_user');

                const hasValidTokens = xsrfToken && sdUser;

                if (!hasValidTokens && !isPublicEndpoint && !isRefreshEndpoint) {
                    // Clear authentication state immediately
                    if (this.logoutHandler) {
                        await this.logoutHandler(true);
                    } else {
                        console.log('Logout handler not set, redirecting to login page.');
                        window.location.href = '/login';
                    }
                    return Promise.reject(
                        new axios.Cancel('User is not authenticated')
                    );
                }

                // Apply additional interceptors/middleware if any
                config = this.requestInterceptorManager.applyInterceptors(config);

                if (!isPublicEndpoint && !isRefreshEndpoint) {
                    config.headers['x-xsrf-token'] = this.xsrfToken || xsrfToken || '';
                }

                return config;
            },
            (error) => {
                logger.error('Request Interceptor Error:', error);
                return Promise.reject(error);
            }
        );

        instance.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => this._handleResponseError(error)
        );

        return instance;
    }

    /**
     * Determines if a URL is considered a public endpoint.
     */
    private _isPublicEndpoint(url?: string): boolean {
        if (!url) return false;
        const parsedURL = new URL(url, this.baseURL);
        const pathname = parsedURL.pathname.toLowerCase();

        // Allow all public endpoints
        const isPublic = publicEndpoints.some(
            (endpoint) => endpoint.toLowerCase() === pathname
        );

        // Additionally, allow the logout endpoint
        const isLogout = pathname === '/authn/logout';

        return isPublic || isLogout;
    }

    /**
     * Determines if a URL is the token refresh endpoint.
     */
    private _isRefreshEndpoint(url?: string): boolean {
        if (!url) return false;
        const parsedURL = new URL(url, this.baseURL);
        const pathname = parsedURL.pathname.toLowerCase();

        return pathname === '/authn/refresh';
    }

    /**
     * Handles response errors, including token refresh logic.
     */
    private async _handleResponseError(error: unknown) {
        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }

        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError;
            const originalRequest = axiosError.config as ExtendedAxiosRequestConfig;

            if (axiosError.response) {
                const { status } = axiosError.response;

                const isRefreshEndpoint = this._isRefreshEndpoint(originalRequest.url);

                if (status === 401) {
                    if (isRefreshEndpoint) {
                        // Token refresh endpoint failed, cannot do anything else
                        console.log('Token refresh failed, calling logout.');
                        await this.logout(true);
                        return Promise.reject(new axios.Cancel('User is not authenticated'));
                    }

                    if (!originalRequest._retry) {
                        console.log('401 Unauthorized detected. Attempting token refresh.');
                        originalRequest._retry = true;

                        try {
                            if (!this.isRefreshing) {
                                this.isRefreshing = true;
                                this.refreshTokenPromise = this._handleTokenRefresh();
                            }

                            const newToken = await this.refreshTokenPromise;
                            this.isRefreshing = false;

                            if (newToken) {
                                this.xsrfToken = newToken;
                                this.instance.defaults.headers.common['x-xsrf-token'] = newToken;

                                // Retry the original request
                                return this.instance(originalRequest);
                            } else {
                                // Token refresh failed, logout
                                console.log('Token refresh failed, calling logout.');
                                await this.logout(true);
                                return Promise.reject(new axios.Cancel('User is not authenticated'));
                            }
                        } catch (err) {
                            logger.error('Token refresh failed', err);
                            await this.logout(true);
                            return Promise.reject(new axios.Cancel('User is not authenticated'));
                        }
                    } else {
                        // Already retried, logout
                        console.log('401 Unauthorized after retry, calling logout.');
                        await this.logout(true);
                        return Promise.reject(new axios.Cancel('User is not authenticated'));
                    }
                }
            }

            logger.error('Unhandled error', axiosError);
            return Promise.reject(
                new ApiError(
                    axiosError.message,
                    axiosError.response?.status,
                    axiosError
                )
            );
        } else {
            logger.error('Unhandled error', error);
            return Promise.reject(new ApiError('Unknown error', undefined, error));
        }
    }

    /**
     * Handles user logout by invoking the logout handler.
     */
    private async logout(skipApiCall = true) {
        console.log('Axios logout method called. Logout handler:', this.logoutHandler);
        if (this.logoutHandler) {
            await this.logoutHandler(skipApiCall);
        } else {
            console.log('Logout handler not set, redirecting to login page.');
            window.location.href = '/login';
        }
    }

    /**
     * Handles token refresh by making a request to the refresh endpoint.
     */
    private async _handleTokenRefresh(): Promise<string | undefined> {
        try {
            await this.instance.post('/authn/refresh', {}, { withCredentials: true });

            // For web platform, tokens are sent in cookies
            const xsrfToken = getCookieValue('sd_xsrf_token');
            if (xsrfToken) {
                this.setXSRFToken(xsrfToken);
            }

            return xsrfToken;
        } catch (err) {
            logger.error('Token refresh failed', err);
        }
        return undefined;
    }

    /**
     * Sets the XSRF token in the Axios instance.
     */
    setXSRFToken(xsrfToken: string) {
        this.xsrfToken = xsrfToken;
        this.instance.defaults.headers.common['x-xsrf-token'] = xsrfToken;
    }

    /**
     * Sets a custom header in the Axios instance.
     */
    setCustomHeader(key: string, value: string) {
        this.instance.defaults.headers.common[key] = value;
    }

    /**
     * Removes a custom header from the Axios instance.
     */
    removeCustomHeader(key: string) {
        delete this.instance.defaults.headers.common[key];
    }

    /**
     * Updates the base URL of the Axios instance.
     */
    setBaseUrl(baseurl: string) {
        this.baseURL = baseurl;
        this.instance.defaults.baseURL = baseurl;
    }

    /**
     * Resets the authentication token by removing the XSRF token header.
     */
    resetAuthToken() {
        this.xsrfToken = '';
        delete this.instance.defaults.headers.common['x-xsrf-token'];
    }
}

/**
 * Initializes the SDAxios instance with the appropriate base URL.
 */
export const sdAxiosInstance = new SDAxios(
    process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000/local'
);

/**
 * Exports the Axios instance for use throughout the application.
 */
export const AxiosService = sdAxiosInstance.instance;
