// src/components/DropdownMenu/DropdownMenu.tsx
/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, Theme } from '@emotion/react';
import { useTheme } from '../../context/ThemeContext';

interface DropdownMenuProps {
    anchorEl: HTMLElement | null;
    open: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ anchorEl, open, onClose, children }) => {
    const { theme } = useTheme();

    if (!open || !anchorEl) return null;

    const dropdownStyle = (theme: Theme) => css`
    position: absolute;
    top: ${anchorEl.getBoundingClientRect().bottom + window.scrollY}px;
    right: ${window.innerWidth - anchorEl.getBoundingClientRect().right}px;
    z-index: 1000;
    background-color: ${theme.surface};
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: ${theme.borderRadius};
    border: 1px solid ${theme.onSurfaceBorder};
    overflow: hidden;
    min-width: 200px;
    color: ${theme.onSurface};
  `;

    return (
        <div css={dropdownStyle(theme)}>
            <div onClick={onClose} css={overlayStyle}></div>
            {children}
        </div>
    );
};

export default DropdownMenu;

const overlayStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

export const dropdownMenuItemStyle = (theme: Theme) => css`
  padding: 12px 16px;
  cursor: pointer;
  &:hover {
    background-color: ${theme.background};
  }
`;
